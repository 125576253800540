import { template as template_d69f3951a4ab4e8097b72f87552753db } from "@ember/template-compiler";
const WelcomeHeader = template_d69f3951a4ab4e8097b72f87552753db(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
