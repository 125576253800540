import { template as template_c18d23af59de48d388fa417f93104e83 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_c18d23af59de48d388fa417f93104e83(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
