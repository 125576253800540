import { template as template_144d9427fa6140fea0395918ff229b4e } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_144d9427fa6140fea0395918ff229b4e(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
