import { template as template_6a5989f317cf41d1bbce8614d248e22b } from "@ember/template-compiler";
const FKLabel = template_6a5989f317cf41d1bbce8614d248e22b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
